import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DesktopContext } from "../../App";
import { CompactContext } from "../../App";
import CheckboxBox from "./CheckboxBox2";
import ControlledCheckbox from "./ControlledCheckbox";
import { Typography } from "@mui/material";

export default function ContactHowCheckbox({ register }) {
  const desktop = useContext(DesktopContext);
  const compact = useContext(CompactContext);

  ContactHowCheckbox.propTypes = {
    register: PropTypes.func.isRequired,
  };

  var contactbyemail = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"contactbyemail"}
        checkboxID={"contactbyemail"}
      />
    </>
  );

  var contactbyphone = (
    <>
      <ControlledCheckbox
        register={register}
        registerWhat={"contactbyphone"}
        checkboxID={"contactbyphone"}
      />
    </>
  );

  return (
    <>
      {compact && (
        <Typography
          sx={{
            color: "#4F145B !important",
            fontWeight: "500 !important",
            fontSize: desktop ? "1.1rem !important" : "1rem !important",
            mt: desktop ? "1rem !important" : "1rem !important",
            ml: desktop ? "1 !important" : "0 !important",
            mb: desktop ? "0.5rem !important" : "0.5rem !important",
          }}
        >
          How Would You Like To Be Contacted?
        </Typography>
      )}
      <CheckboxBox
        checkboxes={contactbyemail}
        label={"Please contact me by email"}
        checkboxErrorText={null}
      />
      <CheckboxBox
        checkboxes={contactbyphone}
        label={"Please contact me by phone"}
        checkboxErrorText={null}
      />
    </>
  );
}
