import React from "react";
import PropTypes from "prop-types";
import ContactHowCheckboxes from "../../../../../components/checkbox/ContactHowCheckboxes";

// PropTypes
ContactHow.propTypes = {
  errors: PropTypes.object,
  register: PropTypes.func.isRequired,
};

export default function ContactHow({ register }) {
 
  return (
      <ContactHowCheckboxes register={register} />
  );
}
